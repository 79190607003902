import React from 'react';
import { graphql } from 'gatsby';
import { SEO, Section, Container, Banner } from 'components';
import { useWindowSize } from 'hooks';

export const query = graphql`
  query DesignProcessQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    bannerImage: file(relativePath: { eq: "assets/images/design-process.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 90, layout: FULL_WIDTH)
      }
    }
    stages: allSanityStage(sort: { fields: title }) {
      edges {
        node {
          title
          _rawBody
          stageImage {
            asset {
              gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;

const DesignPage = (props) => {
  const { data } = props;
  const { windowWidth } = useWindowSize();
  const site = (data || {}).site;
  const { bannerImage } = data;
  const { edges: stages } = data.stages;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }
  const banner = {
    caption: 'Our Design Process',
    text: 'Let us guide you through, step by step, from consultation to installation.',
    img: bannerImage
  };

  return (
    <main>
      <SEO
        title="Our Design Process"
        description="We invite you to come and visit our design studio To arrange a consultation please call our showroom on 014851176 . An appointment is required to meet with our design team."
        keywords={site.keywords}
      />
      <Banner banner={banner} />
      <Container>
        {stages.map(({ node }, i) => (
          <Section
            key={i}
            img={node.stageImage}
            title={node.title}
            portableText={node._rawBody}
            flipped={i % 2 == 0}
          />
        ))}
      </Container>
    </main>
  );
};

export default DesignPage;
